
import {
  computed, defineComponent, PropType, ref, Ref, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';
import { ITestInfoGetResponseData as IQuizResult } from '@/services/api/lxp-quiz-test/types';

import { useQuizResult } from '@/domains/quiz/composables/useQuizResult';
import TestResults from '@/components/ui/TestResults';
import Names from '@/plugins/vue-router/routeNames';

export default defineComponent({
  name: 'QuizResult',

  components: {
    TestResults,
  },

  props: {
    testInfo: {
      type: Object as PropType<IQuizResult>,
      required: true,
    },
  },

  setup(props) {
    const { t, tc } = useI18n();
    const { testInfo } = toRefs(props);
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref<Boolean>(true);
    const playerSessionId: Ref<number> = computed(() => Number(route.params.playerSessionId));
    const {
      testPassed,
      currentPercent,
      testPassingStatusThreshold,
      testPassingStatusImageSrc,
      results,
      hasResults,
      showAnswers,
      answersButtonName,
      fetchResults,
    } = useQuizResult({ quiz: testInfo, playerSessionId });

    const testPassingStatusTitle: Ref<string> = computed(() => {
      if (testPassed.value) {
        return t('QuizResult.testPassingStatusTitle.succes');
      }

      return t('QuizResult.testPassingStatusTitle.fail');
    });

    const testPassingStatusDescription: Ref<string> = computed(() => t('QuizResult.testPassingStatusDescription', {
      passed: testInfo.value.results?.correctAnswers,
      rightAnswers: `${tc('pluralized.right', testInfo.value.results?.correctAnswers)}
        ${tc('pluralized.answers', testInfo.value.results?.correctAnswers)}`,
      all: testInfo.value.questionsCount,
      currentPercent: currentPercent.value,
    }));

    const onClickShowAnswersHandler = () => {
      router.push({
        name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS,
      });
    };

    const init = async () => {
      try {
        isLoading.value = true;
        await Promise.all([
          fetchResults(),
        ]);
        isLoading.value = false;
      } catch (e) {
        console.error(e);
      }
    };
    init();

    return {
      results,
      hasResults,
      testPassingStatusImageSrc,
      testPassingStatusTitle,
      testPassingStatusDescription,
      testPassingStatusThreshold,
      showAnswers,
      answersButtonName,
      onClickShowAnswersHandler,
    };
  },
});
